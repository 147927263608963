<template>
  <div class="admin-careers">
    <div class="header">
      <h1>Careers</h1>

      <!-- Search Criteria -->
      <form class="form-inline form-search-criteria">
        <div class="custom-select-style">
          <select id="select-department" class="custom-select">
            <option>Choose a department</option>
            <option v-for="dep in departments" :value="dep.id" :key="dep.id">{{ dep.enName }}</option>
          </select>
        </div>

        <div class="custom-select-style">
          <select id="select-location" class="custom-select">
            <option>Choose location</option>
            <option v-for="loc in locations" :value="loc.id" :key="loc.id">{{ loc.enName }}</option>
          </select>
        </div>

        <button type="button" class="btn btn-search" @click.prevent="updatePostings">Search</button>
      </form>

      <router-link tag="a" class="btn" to="/admin/careers/new">
        <img src="../../assets/img/admin-career/add-new.svg">Add a new posiiton
      </router-link>
    </div>
    <Accordian
      ref="careerPostings"
      class="admin-accordian"
      :admin="true"
      :departments="departments"
      :locations="locations"
    />
  </div>
</template>

<script>
import $ from "jquery";
import "select2";
import Accordian from "@/components/careers/OpenPositionAccordian.vue";
import axios from "axios";

export default {
  name: "admin-careers",
  head: {
    title: { inner: "Admin-Careers" }
  },
  components: {
    Accordian
  },
  data: function() {
    return {
      departments: [],
      locations: []
    };
  },
  created() {
    // this.getLocation();
    // this.getDepartments();

    $(document).ready(() => {
      $("select").select2({
        minimumResultsForSearch: -1
      });
    });
  },
  methods: {
    updatePostings() {
      let department = $('#select-department').val();
      let location = $('#select-location').val();
      this.$refs.careerPostings.getPostings(department, location);
    },
    async getLocation() {
      await axios.get("http://testdeployd.aezypay.com/location").then(res => (this.locations = res.data));
    },
    async getDepartments() {
      await axios.get("http://testdeployd.aezypay.com/departments").then(res => (this.departments = res.data));
    },
  }
};
</script>

<style lang="scss">
@import "../../../node_modules/select2/dist/css/select2.css";
@import "../../../node_modules/select2-theme-bootstrap4/dist/select2-bootstrap.css";

.admin-careers {
  background: #f9f9f9;
  padding: 50px;
  min-height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    h1 {
      color: #6479ae;
      font-size: 22px;
      font-weight: 600;
    }
    .btn {
      background-color: #6479ae;
      border-radius: 30px;
      padding: 7px;
      color: white;
      width: 300px;
      height: 42px;
      img {
        width: 36px;
        padding-right: 10px;
      }
    }
  }
  .admin-accordian {
    padding: 0 40px;
  }
}

.btn-search {
  // Search Criteria: Button - Search
  color: white;
  font-size: 14px;
  font-weight: 300;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8289a3),
    color-stop(50%, #7882a5),
    to(#6e7ba6)
  );
  background-image: linear-gradient(to right, #8289a3, #7882a5 50%, #6e7ba6);
  background-repeat: no-repeat;
  border: none;
  padding: 7px 28px;
  border-radius: 17px;
  width: 200px !important;

  &:hover {
    color: white;
  }

  &:focus {
    box-shadow: none;
  }

  @media (max-width: 767.98px) {
    width: 260px;
  }
}

.select2-container {
  @media (min-width: 992px) {
    margin-right: 60px;
    width: 300px !important;
  }

  @media (max-width: 991.98px) {
    margin-right: 30px;
    width: 248px !important;
  }

  @media (max-width: 767.98px) {
    width: 260px !important;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.select2-container--default .select2-selection--single {
  height: 40px;
  border: none;

  &:focus {
    outline: none;
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding: 6px 18px;
  padding-left: 20px;
  border-radius: 20px;
  background-color: #f1f1f2;
  font-size: 14px;
  line-height: 28px;
  color: rgba(#3f599a, 0.7);
  text-align: left;

  &:focus {
    outline: none;
  }
}

.select2-container--open {
  .select2-selection--single .select2-selection__rendered {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  &:focus {
    outline: none;
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 6px;
  right: 11px;
}

.select2-dropdown {
  border-left: none;
  border-bottom: none;
  border-right: none;
}

.select2-results__option {
  color: rgba(#3f599a, 0.7);
  padding-left: 20px;
  font-size: 14px;
  line-height: 28px;

  &:last-child {
    padding-bottom: 11px;
  }
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: none;
  background-color: #f1f1f2;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.select2-container--default .select2-results__option[aria-selected="true"],
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3f599a;
  color: white;
}

.custom-select-style {
  @media (min-width: 576px) and (max-width: 767.98px) {
    margin-right: 20px;
  }
}

.select2-container--default .select2-selection--single {
  background-color: transparent;
}
</style>
